<template>
  <div class="bg-white shadow pt-2 pb-2 rounded">
    <div class="w-full flex justify-between px-2">
      <p class="font-barlow text-mdl font-bold text-grey-light mt-1">
        {{ locatedIn === 'overview' ? 'Invites' : 'History of Invites Usage' }}
      </p>
      <div class="flex items-center">
        <p class="font-medium text-grey-light text-sm-2">
          Allow unlimited invites
        </p>
        <p class="font-medium text-blue-dark-1 text-sm-2 w-2 mr-1 ml-3">
          {{ allowUnlimitedInvites ? 'On' : 'Off' }}
        </p>
        <Toggle :value="allowUnlimitedInvites" :onChange="toggleAllowUnlimitedInvites" />
      </div>
    </div>
    <div :class="locatedIn === 'overview' && 'px-2'">
      <div v-if="locatedIn === 'overview'" class="my-3 flex justify-between w-full rounded p-2 bg-blue-light-1">
        <p class="text-grey-dark-2 text-mdh">
          Current number of invites available to this company.
        </p>
        <p class="text-grey-dark-1 font-bold text-l-2" v-if="invitesInfo">
          {{ allowUnlimitedInvites ? 'Unlimited' : invitesInfo.totalRemaining }}
        </p>
      </div>
      <div>
        <p v-if="locatedIn === 'overview'" class="text-grey-dark-1 font-bold text-mdlh">
          History of Invites Usage
        </p>
        <div class="p-2 max-w-3/5">
          <p class="text-grey-dark-2 text-mdh">
            Any activity recorded on invites is listed in this section. A Company Admin can only redeem invites by
            sending out assessment invitations to participants. While an ISM Seller Admin can both add or remove invites
            and enable the option for unlimited invites.
          </p>
        </div>
        <InvitesHistoryTable :class="locatedIn === 'invites' && 'px-2'" :data="invitesHistory" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import InvitesHistoryTable from '../InvitesHistoryTable';
import Toggle from '@/components/Toggle/Toggle.vue';

export default {
  name: 'Licenses',
  components: { InvitesHistoryTable, Toggle },
  props: { locatedIn: { type: String, default: 'overview' }, invitesInfo: { type: Object, default: () => ({}) } },
  computed: {
    ...mapState({
      currentCompany: state => state.companies.currentCompany,
      invitesHistory: state => state.invites.invitesHistory,
      allowUnlimitedInvites: state => state.invites.allowUnlimitedInvites
    }),
  },
  methods: {
    ...mapActions({ openModal: 'modals/openModal', getInvitesHistoryByOrg: 'invites/getInvitesHistoryByOrg' }),
    toggleAllowUnlimitedInvites() {
      this.openModal({
        modal: 'toggleUnlimitedInvitesModal',
        props: { isCurrentlyUnlimited: this.allowUnlimitedInvites },
      });
    },
  },
  async mounted() {
    await this.getInvitesHistoryByOrg(parseInt(this.$route.params.companyId, 10));
  }
};
</script>
