<template>
  <div>
    <div class="flex w-full items-center justify-between">
      <p
        v-if="data.length === 0 && this.responsible === ''"
        class="text-grey-dark-1 font-medium text-mdlh"
      >
        No invites added yet.
      </p>
      <InputField
        v-else
        label="Filter by"
        placeholder="Search by responsible"
        leftIcon
        :onChange="(value) => (filter = value)"
        :defaultValue="filter"
        class="w-inputField"
      />
      <!-- Add actual history in the future -->
      <div class="flex">
        <Button
          v-if="data.length !== 0"
          :disabled="isUnlimited"
          text="Remove Invites"
          type="secondary"
          size="medium"
          :iconLeft="minus"
          @click.native="removeInvites"
          class="mr-2"
        />
        <Button
          text="Add Invites"
          type="primary"
          :iconLeft="add"
          :disabled="isUnlimited"
          size="medium"
          @click.native="addInvites"
          data-testid="add-invites-button"
        />
      </div>
    </div>
    <div v-if="data.length !== 0">
      <p class="my-2 text-grey-dark-2 text-sm-2">
        Showing {{ data.length }} Entries
      </p>
      <div>
        <div class="grid grid-cols-6 items-center bg-grey-light-3 py-0.4">
          <TableHeader hasSorting @click.native="sort(0)" :arrowDirection="this.history.sorting[0] === -1">DATE</TableHeader>
          <TableHeader>NO. OF INVITES</TableHeader>
          <TableHeader>ACTIVITY</TableHeader>
          <TableHeader>INVITES REMAINING</TableHeader>
          <TableHeader>RESPONSIBLE NAME</TableHeader>
          <TableHeader>RESPONSIBLE ROLE</TableHeader>
        </div>

        <VirtualList
          style="min-height: 200px; max-height: 364px; overflow-y: auto"
          :data-key="'uuid'"
          :data-sources="data"
          :data-component="itemComponent"
          data-testid="invites-history-table"
        />
      </div>
    </div>
  </div>
</template>

<script>
import add from "@/assets/img/icons/add.svg";
import minus from "@/assets/img/icons/minus.svg";
import TableHeader from "@/components/Table/TableHeader.vue";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import { mapActions, mapState } from "vuex";
import debounce from 'lodash/debounce';

export default {
  name: "InvitesHistoryTable",
  components: { TableHeader, VirtualList },
  props: {
    data: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    ...mapState({
      responsible: state => state.invites.responsibleQuery,
      company: state => state.companies.currentCompany,
      isUnlimited: state => state.invites.allowUnlimitedInvites,
      history: state => state.invites.history
    }),
    filter: {
      get() {
        return this.responsible;
      },
      set: debounce(function(val) {
          return this.setResponsible({ responsible: val, orgId: this.company.id })
      }, 500)
    }
  },
  data: () => ({ add, minus, itemComponent: ListItem, }),
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      setResponsible: "invites/setResponsible",
      sortHistory: "invites/sortHistory"
    }),
    removeInvites() {
      this.openModal({ modal: "removeInvitesModal" });
    },
    sort(idx) {
      this.sortHistory(idx);
    },
    addInvites() {
      this.openModal({
        modal: "addInvitesModal",
      });
    },
  },
};
</script>
